a.thumbnail {
  position : relative;
  //border-width : 3px;
  color:@text-color;
  & .caption {
    & .title {
      font-weight: bold;
    }
    & .intro {

    }
    & span {
      display      : inline-block;
      padding      : 2px 6px;
      margin-right : 4px;
      font-size    : @font-size-base*0.8;
    }
    &.ontop {
      position         : absolute;
      left             : 6px;
      right            : 6px;
      bottom           : 6px;
      padding          : 16px;
      height           : 55px;
      background-color : @gray-darker;
      opacity          : 0.75;
      //background-color:rgba(0,0,0,0.8);
      //background-color:rgba(28,172,206,0.8);
      color            : rgba(255, 255, 255, 0.9);
      white-space      : nowrap;
      overflow         : hidden;
      text-overflow    : ellipsis;
      & .help-block {
        color : rgba(255, 255, 255, 0.5);
      }
    }
  }
  &:hover {
    border-color : transparent;
    & .caption {
      &.ontop {
        opacity : 1;
      }
    }
  }
}

//a.image {
//  display       : block;
//  position      : relative;
//  margin-bottom : @grid-gutter-width !important;
//  & img {
//    filter : grayscale(50%);
//  }
//  & div.caption {
//    display          : block;
//    transition       : all .2s ease-out;
//    position         : absolute;
//    right            : 0px;
//    bottom           : 0px;
//    left             : 0px;
//    line-height      : 1.2;
//    background-color : rgba(0, 0, 0, 0.50); //@brand-primary;
//    padding          : @padding-base-vertical*2.5 @padding-base-horizontal*2.2;
//    color            : #fff;
//    & small {
//      //color     : lighten(@brand-primary, 20%); //darken(@brand-primary, 20%);
//      font-size : @font-size-base*0.8;
//    }
//    &.main {
//      font-size : @font-size-base*1.2;
//    }
//  }
//  &:hover {
//    text-decoration : none;
//    & div.caption {
//      display    : block;
//      background-color : rgba(243, 132, 0, 0.65);
//      transition : all .2s ease-in;
//      //top            : 0px;
//    }
//    & img {
//      filter : blur(0px) grayscale(0%);
//    }
//    & div.title {
//      background-color : rgba(0, 0, 0, 0.65);
//    }
//  }
//  &.active {
//    & div.caption {
//      display    : block;
//      background-color : rgba(206, 6, 32, 0.50); //@brand-primary;
//      transition : all .2s ease-out;
//    }
//  }
//}
//a.productimage {
//  display       : block;
//  position      : relative;
//  margin-bottom : @grid-gutter-width !important;
//  & img {
//    filter : grayscale(50%);
//  }
//  & div.caption {
//    display          : block;
//    transition       : all .2s ease-out;
//    //position         : absolute;
//    right            : 0px;
//    bottom           : 0px;
//    left             : 0px;
//    line-height      : 1.2;
//    //background-color : rgba(0, 0, 0, 0.50); //@brand-primary;
//    padding          : @padding-base-vertical*2.5 @padding-base-horizontal*2.2;
//    //color            : #fff;
//    & small {
//      //color     : lighten(@brand-primary, 20%); //darken(@brand-primary, 20%);
//      font-size : @font-size-base*0.8;
//    }
//    &.main {
//      font-size : @font-size-base*1.2;
//    }
//  }
//  &:hover {
//    border:1px solid #ffffff;
//    //background-color : rgba(0, 0, 0, 0.02);
//    text-decoration : none;
//    box-shadow: 0px 0px 6px rgba(0,0,0,0.5);
//    & div.caption {
//      display    : block;
//      transition : all .2s ease-in;
//      //top            : 0px;
//    }
//    & img {
//      filter : blur(0px) grayscale(0%);
//    }
//    & div.title {
//      background-color : rgba(0, 0, 0, 0.65);
//    }
//  }
//  &.active {
//    & div.caption {
//      display    : block;
//      background-color : rgba(206, 6, 32, 0.50); //@brand-primary;
//      transition : all .2s ease-out;
//    }
//  }
//}
//
//a.video {
//  display       : block;
//  position      : relative;
//  margin-bottom : @grid-gutter-width !important;
//  & img {
//    filter : grayscale(50%);
//  }
//  & div.caption {
//    display          : none;
//    transition       : all .2s ease-out;
//    position         : absolute;
//    bottom           : 0px;
//    top              : 0px;
//    right            : 0px;
//    bottom           : 0px;
//    left             : 0px;
//    line-height      : 1.2;
//    background-color : rgba(0, 0, 0, 0.50); //@brand-primary;
//    padding          : @padding-base-vertical*2.5 @padding-base-horizontal*2.2;
//    color            : #fff;
//    & small {
//      //color     : lighten(@brand-primary, 20%); //darken(@brand-primary, 20%);
//      font-size : @font-size-base*0.8;
//    }
//    &.main {
//      font-size : @font-size-base*1.2;
//    }
//  }
//  &:hover {
//    text-decoration : none;
//    & div.caption {
//      display    : block;
//      transition : all .2s ease-out;
//    }
//    & img {
//      filter : blur(0px) grayscale(0%);
//    }
//    & div.title {
//      background-color : rgba(0, 0, 0, 0.65);
//    }
//  }
//  &.active {
//    & div.caption {
//      display    : block;
//      background-color : rgba(206, 6, 32, 0.50); //@brand-primary;
//      transition : all .2s ease-out;
//    }
//
//  }
//  //&:hover {
//  //  color           : inherit;
//  //  text-decoration : none;
//  //}
//}
//
//.projects {
//  & a.image {
//    display       : block;
//    position      : relative;
//    margin-bottom : @grid-gutter-width !important;
//    & img {
//      filter : grayscale(50%);
//    }
//    & div.caption {
//      display          : inline;
//      transition       : all .2s ease-out;
//      position         : absolute;
//      //right            : 0px;
//      bottom           : 20px;
//      left             : 0px;
//      line-height      : 1.2;
//      background-color : #000;//rgba(0, 0, 0, 0.50); //@brand-primary;
//      padding          : @padding-base-vertical*2 @padding-base-horizontal*1.8;
//      color            : #fff;
//      font-family:"Open Sans Condensed";
//      font-size : @font-size-base*1.4;
//      font-weight: 300;
//    }
//    & div.location {
//      display          : inline;
//      transition       : all .2s ease-out;
//      position         : absolute;
//      //right            : 0px;
//      top           : 20px;
//      right             : 0px;
//      line-height      : 1.2;
//      background-color : @brand-primary;//rgba(0, 0, 0, 0.50); //@brand-primary;
//      padding          : @padding-base-vertical*2 @padding-base-horizontal*1.8;
//      color            : #fff;
//      font-family:"Open Sans Condensed";
//      font-size : @font-size-base;
//      font-weight: 700;
//    }
//    &:hover {
//      text-decoration : none;
//      & div.caption {
//        display    : block;
//        background-color : rgba(243, 132, 0, 0.85);
//        transition : all .2s ease-in;
//        //top            : 0px;
//      }
//      & img {
//        filter : blur(0px) grayscale(0%);
//      }
//      & div.title {
//        background-color : rgba(0, 0, 0, 0.65);
//      }
//    }
//    &.active {
//      & div.caption {
//        display    : block;
//        background-color : rgba(243, 132, 0, 0.5); //@brand-primary;
//        transition : all .2s ease-out;
//      }
//    }
//  }
//
//}
