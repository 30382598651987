h1, h2, h3, h4, h5, h6 {
  //color : lighten(@text-color, 10%);
  //& small, & span.small {
  //  font-weight:normal !important;
  //}
}

h1 {
  text-transform: uppercase;
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

& a {
  color: @brand-primary;
  &:hover {
    text-decoration: none;
    color: lighten(@brand-primary, 20%);
    //    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
  &.disabled {
    opacity: 0.5;
  }
}

small {
  &.light {
    color: lighten(@text-color, 20%);
  }
  &.dark {
    color: darken(@text-color, 20%);
  }
}

u {
  text-decoration: none;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
  blockquote {
    //padding : @padded*3;
    //margin  : @padded*3 0;
  }
}

form.form-horizontal {
  & span.help-block {
    .small();
  }
}

form.form-horizontal {
  & span.help-block {
    .small();
  }
}

.fa_linkedin {
  color: @linkedin;
  &:hover {
    color: lighten(@linkedin,10%);
  }
}

.fa_skype {
  color: @skype;
  &:hover {
    color: lighten(@skype,10%);
  }
}

.fa_facebook {
  color: @facebook;
  &:hover {
    color: lighten(@facebook,10%);
  }
}

.fa_twitter {
  color: @twitter;
  &:hover {
    color: lighten(@twitter,10%);
  }
}

.fa_googleplus {
  color: @googleplus;
  &:hover {
    color: lighten(@googleplus,10%);
  }
}

.fa_youtube {
  color: @youtube;
  &:hover {
    color: lighten(@youtube,10%);
  }
}

.fa_instagram {
  color: @instagram;
  &:hover {
    color: lighten(@instagram,10%);
  }
}

//  th[scope=row] { border-right: 2px solid #333; } { border-right: 2px solid #333; }
//}