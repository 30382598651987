.flash-message {
  z-index: 100000;
  position: absolute;
  top: 65px;
  width: 80%;
  max-width: 80%;
  min-width: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  & .alert {
    border: 3px solid @brand-primary;
    line-height: 1;
    & *:first-child {
      margin-top: 0px;
    }
    & *:last-child {
      margin-bottom: 0px;
    }
    & h3 {
      font-size: @font-size-h4;
      line-height: 1.2;
      margin: 0;
    }
    & p {
      font-size: @font-size-base;
      line-height: 1;
    }
  }
}

// Page header
// -------------------------
.page-header {
  z-index: 999;
  display: block;
  margin: -30px 0px 0px;
  padding: 15px 0px;
  position: relative;
  background-color: @brand-primary;
  color: #fff;
  border: none;

  & h1 {
    margin: 0;
    padding: 0;
    text-transform:none;
    color: inherit;
    border: none;
  }
}

.content {

  a:not(.btn) {
    color: @brand-primary;
    //font-weight : bold;
    &:hover {
      color: lighten(@brand-primary, 10%)
    }
  }
  strong {
    // color : lighten(@text-color, 10%);
  }

  .img-margin {
    margin: @line-height-computed @line-height-computed;
    max-width: 95%;
  }

  & .youtube, &.vimeo {
    &.thumbnail {
      background-color: #fff;
      border: none;
      color: #fff;
      margin: @grid-gutter-width 0;
      & h3 {
        line-height: 1.2;
        margin: 4px 0;
        & i {
          color: @brand-primary;
        }
      }
    }
  }

  & a.thumbnail {
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }
  }

  // Blockquotes
  & blockquote {
    padding: 15px;
    margin: 10px 0;
    font-size: @font-size-base * 1.2;
    border: none;
    font-weight: 100;
    font-style: italic;
    line-height: 1.4;
    //background-color: Lighten(@gray-lighter,2%);
    //background-color: #daf3ff;
    //.bg-primary().lighter();
    background-color: transparent;
    p, ul, ol {
      &:last-child {
        margin-bottom: 0;
      }
    }
    quotes: "\201C" "\201D" "\2018" "\2019";
    & > *:before {
      content: open-quote;
    }
    & > *:after {
      content: close-quote;
    }
    .small {
      color: @gray;
    }
  }
  .columns {
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px;
    &.columns-2 {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    &.columns-3 {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    &.columns-4 {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
  }

  // Simple ordered list styling
  & ol:not(.pagination):not(.list):not(.navbar-nav):not(.breadcrumb) {
    list-style-type: none;
    list-style-type: decimal !ie; /*IE 7- hack*/

    margin: 30px 0 30px 0;
    margin-left: 2.5em;
    padding: 0;

    counter-reset: li-counter;
    & li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 0.5em;
      min-height: 3em;
      border-left: 2px solid #cccccc;
      &:before {
        position: absolute;
        top: 0;
        left: -1.5em;
        width: 0.8em;

        font-size: 2em;
        line-height: 1;
        font-weight: bold;
        text-align: right;
        color: @brand-primary;

        content: counter(li-counter);
        counter-increment: li-counter;
      }
    }
  }

  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion):not(.nav-pills):not(.nav-tabs) {
    list-style-type: none;
    padding-left: 0px;
    margin: 20px 0 20px 20px; // 0 0 15px;
    & li {
      margin: 4px 0px;
      padding-left: 10px;
      &:before {
        content: '\f105';
        font-family: 'FontAwesome';
        color: @brand-primary;
        float: left;
        margin: 0 0 0 -15px;
        font-weight: 600;
      }
    }
  }
  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion):not(.nav-tabs).check {
    margin: 15px 0 15px 10px; // 0 0 15px;
    & li {
      font-size: @font-size-base;
      margin: 10px 0;
      &:before {
        font-family: 'FontAwesome';
        font-size: @font-size-base;
        content: '\f00c';
        color: @brand-secundary;
        margin: 0px 0 0 -20px;
      }
    }
    &.secundary {
      & li {
        &:before {
          color: @brand-secundary;
        }
      }
    }
  }

  & table.specs {
    & tr {
      & td {
        &.caption {
          padding-top: 8px;
          font-weight: bold;
          border-bottom: 1px solid @gray-light;
        }
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }
}

.tags {
  & a {
    display: inline-block;
    margin: 0 0 4px 0;
    & span.label {
      margin: 0;
    }
    &:hover {
      & span.label {
        background-color: lighten(@label-default-bg, 10%);
      }
    }
  }
}

.bordered {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
}

