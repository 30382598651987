.swiper-container {
  height: 400px; // old browsers
  height: 70vh; // overruled by style
  & .swiper-slide {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    & .caption {
      position: absolute;
      left: 60px;
      bottom: 60px;
      width: 40%;
      max-width: 40%;
      background-color: rgba(0, 0, 0, 0.40);
      color: white;
      padding: 16px 32px;
      font-size: @font-size-base*1.2;
      text-shadow: @carousel-text-shadow;
      & h1, & h2, & h3, & h4, & h5 {

      }
    }
  }
  & .swiper-pagination {
    & .swiper-pagination-bullet {
      background-color: transparent;
      opacity: 0.5;
      width: 15px;
      height: 15px;
      border: 1px solid #fff;
    }
    & .swiper-pagination-bullet-active {
      background-color: @brand-primary;
      opacity: 1;
    }
  }

  &.inpage {
    height: auto;
    & .swiper-slide {
      background-color: #e3eef7;
      color: @text-color;
      font-family: "Lato", Helvetica, Arial, sans-serif;
      font-size: @font-size-base;
      //padding: @padding*1.5 @padding*2;
    }
  }

}

@media (max-width: @screen-sm-max) {
  .swiper-container {
    & .swiper-slide {
      font-size: 2.2vh;
      & .caption {
        right: 60px;
        bottom: 30px;
        & .title {
          text-align: right;
          text-shadow: @carousel-text-shadow;
          font-weight: 700;
          text-transform: uppercase;
        }
        & .subtitle {
          text-align: right;
          text-shadow: @carousel-text-shadow;
          font-size: 1.8vh;
        }
      }
    }
  }
}

.fullbackground {
  position: relative;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;

  & .caption {
    position: absolute;
    left: 60px;
    bottom: 60px;
    width: 40%;
    max-width: 40%;
    background-color: rgba(0, 0, 0, 0.40);
    color: white;
    padding: 16px 32px;
    font-size: @font-size-base*1.2;
    text-shadow: @carousel-text-shadow;
    & h1, & h2, & h3, & h4, & h5 {

    }
  }

  //& .swiper-header {
  //  display    : none;
  //  position   : absolute;
  //  top        : 0px;
  //  left       : 0px;
  //  right      : 0px;
  //  z-index    : 9999;
  //  min-height : 115px;
  //
  //  & h1 {
  //    margin         : 0;
  //    padding        : 0;
  //    text-transform : capitalize;
  //    color          : #fff;
  //    height         : 30px;
  //    line-height    : 30px;
  //  }
  //  & h2 {
  //    margin      : 0;
  //    padding     : 0;
  //    font-size   : @font-size-h2*0.8;
  //    color       : #fff;
  //    height      : 30px;
  //    line-height : 30px;
  //  }
  //}

}
