.navigation {
  z-index: 1000;
  position: absolute;
  width: 100%;
  & .featured {
    //position:absolute;
    //top:0;
    //right:10px;
    margin: 0 20px 0 0;
    & ul.list.list-inline {
      margin: 0;
      & li {
        padding: 0;
        margin: 0;
        & a.btn {
          display: inline-block;
          margin: 0;
        }

      }
    }
  }

  & .topnav {
    //z-index          : 1000;
    @TopNavColor: @gray-darker;
    width: 100%;
    overflow: visible;
    height: 35px;
    line-height: 35px;
    font-size: @font-size-base*.9;
    background-color: @TopNavColor;
    box-shadow: inset 0 -7px 20px -7px darken(@gray-darker, 20%); //rgba(0,0,0,1);

    & ul.list.list-inline {
      margin: 0px;
      & li {
        color: rgba(255, 255, 255, 0.65);
        padding: 0;
        & a {
          display: block;
          color: rgba(255, 255, 255, 0.75);
          padding-left: 6px;
          padding-right: 6px;
        }
        &:hover {
          background-color: darken(@TopNavColor, 10%);
          a {
            color: rgba(255, 255, 255, 1);
            & i {
              color: @brand-primary;
              //margin:0;
            }
          }
        }
        &.dropdown {
          &:active {
            text-decoration: none;
          }
          & ul.dropdown-menu {
            font-family: "Open Sans";
            & li {
              background-color: #fff;
              & a {
                text-decoration: none;
                padding: 4px 10px;
                color: @text-color;
                font-size: 14px;
              }
              &:hover {
                & a {
                  background-color: darken(#fff, 10%);
                  color: @text-color;
                  font-size: 14px;
                }
              }
            }
          }
        }
        &.search {
          float: left;
          & form {
            float: left;
            margin: 0;
            padding: 7px 0 0 0;
            & input {
              //float            : left;
              height: 25px;
              width: 150px;
              margin: 0;
              padding: 2px 4px;
              background-color: @TopNavColor; //@brand-primary;
            }
          }
        }
        &.flag {
          height: Inherit;
          line-height: Inherit;
          //border-left : 1px solid @gray;
          & a {
            img {
              border: 1px solid #fff;
              margin-top: -2px;
              height: 18px;
              width: auto;
              opacity: 0.75;
            }
          }
          &:hover {
            & a img {
              opacity: 1;
            }
          }
        }
        &.social {
          border-left: 1px solid lighten(@TopNavColor, 10%);
          height: Inherit;
          line-height: Inherit;
          &:last-child {
            border-right: 1px solid lighten(@TopNavColor, 10%);
          }
          & a {
            display: block;
            font-size: 120%;
            text-align: center;
            margin: 0 !important;
            & i {
              margin: 0 !important;

            }
          }
        }

      }
      &.search {
        margin: 0px 4px;
        overflow: hidden;
        & .form-control {
          display: inline;
          width: auto;
        }
      }
    }
  }

  /* Small devices (Smartphones, up to 768px) */
  & nav {
    //z-index : 9999;
    &.navbar {
      font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      height: auto;
      background-color: rgba(255, 255, 255, 0.80); // transparent;
      transition: all .2s ease-out;
      & .navbar-header {
        height: inherit;
        & button.navbar-toggle {
          position: absolute;
          padding: 8px 18px;
          margin: 16px 15px 0 0;
          color: @brand-primary;
          background-color: @gray-light;
          border: none;
          right: 0px;
          font-size: 20px;
          &:hover {
            background-color: @brand-primary;
            color: #fff;
          }
        }
        & .navbar-brand {
          padding: 0px 15px;
          margin: 0px;
          height: 100%;
          & img {
            transition: all .2s ease-out;
            //box-shadow : 0px 0px 5px rgba(0, 0, 0, 0.5);
            &.shield {
              position: absolute;
              display: block;
              margin: 5px 0px 0px 0px;
              width: 200px;
              transition: all 0.4s ease;
              &.hide {
                .transition(opacity 0.5s, margin 0.25s;);
                transition: all 0.4s ease;
              }
            }
          }
        }
      }
      & .navbar-collapse {
        & ul.navbar-nav {
          & > li > a {
            text-transform: uppercase;
            padding-left: 8px;
            padding-right: 8px;
          }
          & ul.dropdown-menu {
            background-color: #fff;
            font-family: "Open Sans";
            & > li > a {

              font-size: 16px;
            }
          }
        }
      }
      &.affix {
        transition: all .2s ease-out;
        position: fixed;
        .navbar-fixed-top();
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
        background-color: #fff; //@gray-darker;
        & .navbar-header {
          & .navbar-brand {
            & img {
              box-shadow: none;
              &.shield {
                position: absolute;
                display: block;
                margin: 9px 0px 0px 0px;
                width: 235px;
                transition: all 0.4s ease;
                &.hide {
                  .transition(opacity 0.5s, margin 0.25s;);
                  transition: all 0.4s ease;
                }
              }
            }
          }
        }
      }
    }
  }
}

//#nav-mobile {
//  display:none;
//}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
  .navigation {

    & nav {
      &.navbar {
        height: auto;
        & .navbar-header {
          height: auto;
          & .navbar-brand {
            & img {
              transition: all .2s ease-out;
              &.shield {
                margin: -2px 0px 0px 0px;
                width: 300px;
              }
            }
          }
        }
        & .navbar-collapse {
          & ul.navbar-nav {
            & > li > a {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
        &.affix {
          //position         : fixed;
          //.navbar-fixed-top();
          & .navbar-header {
            & .navbar-brand {
              & img {
                &.shield {
                  width: 235px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
* Unstyled list
*/
.navmenu {
  position: absolute;
  & ul.navmenu-nav {
    & li {
      & a {
        padding: 4px 16px;
      }
    }
  }
}

.sidebar {
  & .panel {
    background-color: transparent;
    & .panel-heading {
      & a {
        color: @text-color;
        &:hover {
          & h4 {
            color: @brand-primary;
          }
        }
      }
    }
    & ul.list-group {
      & a {
        & .menu {
          display: block;
          font-weight: bold;
        }
        & .title {
          font-size: @font-size-base*0.9;
          color: lighten(@text-color, 20%);
          display: block;
        }
        &.active {
          & .menu {
            color: #fff;
          }
          & .title {
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
    }
  }

  ul.list-unstyled {
    & li {
      font-weight: normal;
      & > a {
        display: block;
        color: @text-color;
        padding: @padding-small-vertical;
      }
      &:hover {
        & > a {
          color: @brand-primary;
        }
      }
      &.active {
        & > a {
          font-weight: bold;
        }
      }
      & > ul {
        .list-unstyled();
        margin: 0 0 0 15px;
        & li {
          font-size: @font-size-base * 0.8;
          & > a {
            display: block;
            color: @text-color;
            padding: @padding-small-vertical;
          }
          &:hover {
            & > a {
              color: @brand-primary;
            }
          }
          &.active {
            & > a {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

//ul.list-media {
//  & li {
//    a {
//      & .media {
//        display: block;
//        & .media-left {
//          width: 28%;
//          padding: 8px;
//          & img {
//            filter: grayscale(60%);
//          }
//        }
//        & .media-body {
//          padding-left: 12px;
//          font-size: @font-size-base*0.9;
//          & .media-date {
//            font-size: @font-size-base*0.8;
//            color: lighten(@text-color, 20%);
//          }
//          & .media-heading {
//            margin-top: 4px;
//            //font-size: @font-size-base;
//            font-weight: 600;
//            line-height: 1.2;
//            //color: @text-color;
//          }
//        }
//        &:hover {
//          background-color: @gray-lighter;
//          & img {
//            filter: grayscale(0%); //blur(0px)
//          }
//        }
//      }
//    }
//  }
//  &.active {
//    & a {
//      background-color: @gray-lighter !important;
//    }
//  }
//}

//&.nav-tabs {
//  & li {
//    & a {
//      color: lighten(@text-color, 20%);
//    }
//    &:hover {
//      & a {
//        color: @text-color;
//      }
//    }
//    &:active, &.active {
//      & a {
//        color: @text-color;
//        & i {
//          color: @brand-primary;
//        }
//      }
//    }
//  }
//}

//#sidebar {
//  &.affix {
//    //position   : fixed;
//    //z-index    : 1003;
//    top   : 0;
//    right : 0;
//    //width      : 100%;
//    //box-shadow : 0 2px 3px rgba(0, 0, 0, 0.3);
//  }
//
//}

