@import "bootstrap/bootstrap";
@import "variables"; // Overrides
//@import "variables_sa"; // Overrides

body, label, .checkbox label {
  font-weight: 400;
}

body {
  //padding-top: @navbar-height+34;
  //background: url("/_files/bg_cloud.jpg") no-repeat center top fixed;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  //font-size:14px;
}

html, body {
  height: 100%;
  //font-weight: 300;
}

[data-sr], .reveal {
  visibility: hidden;
}
@import "helpers";

@import (less) "swiper.min.css";
@import "swiper";

//@import "header";

@import "navigation";

//@import "calendar";
//@import "news";
@import "thumbnails";
//@import "downloads";
//@import "video";

@import "modals";
@import "forms";
@import "type";
@import "social";
//@import "plugins";

//@import "isotope";
@import "jasny/jasny-bootstrap";
//@import (less) "example4/colorbox.css";
//@import (less) "example4/colorbox.css";
@import (less) "photoswipe/photoswipe.css";
@import "photoswipe/default-skin/default-skin.css";
@import "photoswipe";

@import "onepage";
//@import "pdf";

@import "content";
@import "footer";

@import url("//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700|Open+Sans:300,400,400italic,600,700,800");

@btn-font-weight: 400;

@import "/public/fonts/font-awesome/css/font-awesome.min.css";
